import { AuthorizationParams } from '@auth0/auth0-react';
import { createRoute, redirect } from '@tanstack/react-router';
import { retrieveUtmParamsSession } from '../../utils/analytics-utils';
import { authRedirectUrl, BASE_URL } from '../../utils/constants';
import { captureException } from '../../utils/error-utils';
import { Route as RootRoute } from '../__root';

export const Route = createRoute({
  getParentRoute: () => RootRoute,
  path: 'signup',
  beforeLoad({ context: { auth } }) {
    if (auth.isAuthenticated) {
      throw redirect({ to: '/overview', replace: true });
    }

    const authParams: AuthorizationParams = {
      'ext-back_link': BASE_URL,
      'ext-distinct_id': getDistinctId(),
      'ext-gtm_id': window.__QDRANT_CLOUD__.gtm_id,
      'ext-host': window.__QDRANT_CLOUD__.auth0.domain.includes('staging')
        ? 'staging-cloud.qdrant.io'
        : 'cloud.qdrant.io',
    };
    // Add utm custom params
    Object.entries(retrieveUtmParamsSession()).forEach(([key, value]) => {
      authParams[`ext-${key}`] = value;
    });

    return auth
      .loginWithRedirect({
        authorizationParams: {
          audience: window.__QDRANT_CLOUD__.auth0.audience,
          scope: window.__QDRANT_CLOUD__.auth0.scope,
          redirect_uri: authRedirectUrl,
          screen_hint: 'signup',
          ...authParams,
        },
      })
      .catch((error) => {
        captureException(error);
        throw error;
      });
  },
});

function getDistinctId() {
  const randomId = window.crypto.randomUUID();
  if ('analytics' in window) {
    return window.analytics.user().anonymousId() ?? randomId;
  }
  return randomId;
}
