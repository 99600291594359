import { createRoute, redirect } from '@tanstack/react-router';
import { isApiClientError } from '../../../api/client';
import { getClusterByIdQuery, getClusterScalableQuery } from '../../../api/services/cluster';
import { getDatabaseUpdateCandidatesQuery } from '../../../api/services/database';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';
import { Route as ClustersRoute } from '../clusters_';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => ClustersRoute,
    path: '$clusterId',
    staticData: {
      permissions: ['read:clusters'],
    },
    beforeLoad: async ({ context: { queryClient }, params }) => {
      const { clusterId, accountId } = params;
      const clusterQueryOptions = getClusterByIdQuery({ account_id: accountId, cluster_id: clusterId });
      try {
        await queryClient.fetchQuery(clusterQueryOptions);
      } catch (error) {
        if (isApiClientError(error) && error.originalStatus === 403) {
          throw redirect({
            to: '/accounts/$accountId/clusters',
            params: { accountId },
            replace: true,
          });
        }

        throw error;
      }

      const databaseUpdateCandidatesQueryOptions = getDatabaseUpdateCandidatesQuery({
        account_id: accountId,
        cluster_id: clusterId,
      });

      return {
        clusterQueryOptions,
        clusterScalableQueryOptions: getClusterScalableQuery({
          account_id: accountId,
          cluster_id: clusterId,
        }),
        databaseUpdateCandidatesQueryOptions,
      };
    },
    loader: async ({ context: { queryClient, databaseUpdateCandidatesQueryOptions } }) => {
      await queryClient.prefetchQuery(databaseUpdateCandidatesQueryOptions);
    },
  }),
).lazy(() => import(/* webpackChunkName: "cluster-id" */ './$clusterId.lazy').then(({ Route }) => Route));
