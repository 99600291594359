import { createRoute } from '@tanstack/react-router';
import { Route as CloudAccessRolesRoute } from './roles';
import { getInvitesByAccountId } from '../../../api/services/user';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';
import { hasPermission } from '../../../utils/access-control';
import { getAccountUser } from '../../_authenticated/_account';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => CloudAccessRolesRoute,
    path: 'all-users',
    staticData: {
      permissions: ['read:account_users'],
    },
    beforeLoad: async ({ context: { store }, params: { accountId } }) => {
      const userAccount = await getAccountUser(store, { account_id: accountId });
      const hasInvitesReadPermission = hasPermission({ permissions: ['read:invites'], userAccount });

      if (hasInvitesReadPermission) {
        return { invitesQueryOption: getInvitesByAccountId({ account_id: accountId }) };
      }
      return { invitesQueryOption: null };
    },
    loader: async ({ context: { queryClient, invitesQueryOption } }) => {
      if (invitesQueryOption) {
        await queryClient.fetchQuery(invitesQueryOption);
      }
    },
  }),
).lazy(() => import(/* webpackChunkName: "all-users" */ './roles.all-users.lazy').then(({ Route }) => Route));
