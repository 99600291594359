import { Options as AnalyticsOptions } from '@segment/analytics-next';
import { useQuery } from '@tanstack/react-query';
import { useState, ReactNode, useEffect, createContext, useContext, useMemo } from 'react';
import { featureFlagsQueryOptions } from '../../api/services/core';
import { useCookieHub } from '../../contexts/cookie-hub-context';
import { useTrackPageView } from '../../hooks/use-event-tracking';
import { retrieveUtmParamsSession } from '../../utils/analytics-utils';

type EnqueuedCallback = (timestamp?: Date /* when the event took place */) => unknown;

const AnalyticsContext = createContext<{
  enqueueEvent: (callback: EnqueuedCallback) => void;
  allowedAnalytics: boolean;
}>({ allowedAnalytics: false, enqueueEvent: (_) => undefined });

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children, disabled = false }: { children: ReactNode; disabled?: boolean }) => {
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
  const allowedAnalytics = !disabled && analyticsLoaded;
  const { data: featureFlagData } = useQuery(featureFlagsQueryOptions);
  const { hasConsented } = useCookieHub();

  useEffect(() => {
    function handleAnalyticsLoaded() {
      setAnalyticsLoaded(true);
    }
    document.addEventListener('gtm_analytics_loaded', handleAnalyticsLoaded, { once: true });
    return () => document.removeEventListener('gtm_analytics_loaded', handleAnalyticsLoaded);
  }, []);

  const [eventQueue] = useState(() => {
    let dispatched = false;
    const queue = new Set<[EnqueuedCallback, Date]>();
    return {
      set dispatchQueue(value: boolean) {
        if (value) {
          queue.forEach(([callback, timestamp]) => {
            callback(timestamp);
          });
          queue.clear();
        }
        dispatched = value;
      },
      enqueue: (callback: EnqueuedCallback) => {
        if (dispatched) {
          callback();
        } else {
          queue.add([callback, new Date()]);
        }
      },
    };
  });

  useMemo(() => {
    /**
     * Append campaign traits to every event with Segment Analytics.js
     */
    const campaign = {} as NonNullable<NonNullable<AnalyticsOptions['context']>['campaign']>;
    const utmParams = retrieveUtmParamsSession();

    if (utmParams.utm_campaign) {
      campaign.campaign = utmParams.utm_campaign;
    }
    if (utmParams.utm_source) {
      campaign.source = utmParams.utm_source;
    }
    if (utmParams.utm_medium) {
      campaign.medium = utmParams.utm_medium;
    }
    if (utmParams.utm_term) {
      campaign.term = utmParams.utm_term;
    }
    if (utmParams.utm_content) {
      campaign.content = utmParams.utm_content;
    }

    eventQueue.enqueue(() => {
      void window.analytics.identify({ version: window.__QDRANT_CLOUD__.version, ...campaign });
    });
  }, [eventQueue]);

  useEffect(() => {
    if (featureFlagData) {
      /**
       * Append feature flag traits to every event with Segment Analytics.js
       */
      eventQueue.enqueue(() => {
        const featureFlags = Object.entries(featureFlagData).reduce<Record<`ff_${string}`, boolean>>(
          (acc, [key, value]) => {
            acc[`ff_${key}`] = value;
            return acc;
          },
          {},
        );
        return window.analytics.identify(featureFlags);
      });
    }
  }, [eventQueue, featureFlagData]);

  useEffect(() => {
    const eventHandler = () =>
      /** @see https://segment.com/docs/privacy/consent-management/consent-in-unify/#segment-consent-preference-updated-event */
      window.analytics.track('segment_consent_preference_updated', undefined, {
        context: {
          consent: {
            categoryPreferences: {
              Analytics: hasConsented('analytics'),
              Advertising: hasConsented('marketing'),
              Functional: hasConsented('necessary'),
              Preferences: hasConsented('preferences'),
            },
          },
        },
      });
    document.addEventListener('gtm_cookiehub_analytics_choice', eventHandler);

    return () => {
      document.removeEventListener('gtm_cookiehub_analytics_choice', eventHandler);
    };
  }, [analyticsLoaded, hasConsented]);

  const value = useMemo(() => {
    eventQueue.dispatchQueue = allowedAnalytics;
    return { enqueueEvent: eventQueue.enqueue, allowedAnalytics };
  }, [allowedAnalytics, eventQueue]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export function AnalyticsRoot({ children }: { children: ReactNode }) {
  const trackPageView = useTrackPageView();
  // SPA Page View Logic
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return children;
}
