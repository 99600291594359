import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { WEB_STORAGE_PREFIX } from '../../../utils/constants';
import { isModuleNotFoundError, captureException, isError } from '../../../utils/error-utils';
import { attempt } from '../../../utils/func-utils';

export function DefaultErrorComponent({
  error,
  children,
  onModuleNotFoundError,
}: {
  error: unknown;
  children?: ReactNode;
  onModuleNotFoundError?: () => void;
}) {
  if (isModuleNotFoundError(error)) {
    // We want to reload one time on module not found error and not enter
    // a reload loop if there is some other issue besides an old deploy.
    // That's why we store our reload attempt in sessionStorage.
    // Use error.message as key because it contains the module path that failed.
    const storageKey = `${WEB_STORAGE_PREFIX}${error.message}`;
    const hasError = attempt(
      () => sessionStorage.getItem(storageKey),
      (error) => {
        // Keep track of errors to know how many sessions DO NOT experience an automatic reload (if any).
        captureException(error, { tags: { webpack: 'ChunkLoadError' } });
        // Return true to mimic that we've already reloaded, as sessionStorage throws, and we can't continue.
        return true;
      },
    );
    // If we haven't reloaded yet, then mark that we are reloading.
    if (!hasError) {
      sessionStorage.setItem(storageKey, '1');
      onModuleNotFoundError?.();
      // Return empty component while we wait for window to reload...
      return <></>;
    }
  }

  return children ? (
    children
  ) : (
    <Box
      role="alert"
      sx={{ position: 'absolute', width: '100%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <Box sx={{ width: '100%', textAlign: 'center', paddingX: '20px' }}>
        <Typography variant="h5" component="p">
          An error has occurred. Our team is aware of the issue and is working to resolve it.
          <br />
          Please try again later.
        </Typography>
        <Typography variant="body2" component="p" sx={{ textAlign: 'center', mb: 2 }}>
          <code>{isError(error) ? error.message : String(error)}</code>
        </Typography>
      </Box>
    </Box>
  );
}
