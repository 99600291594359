import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';
import { getAccountJwts } from '../../api/services/account';
import { getAccountApiKeys } from '../../api/services/authentication';
import { clusterApi } from '../../services/clustersApi';
import { hasPermission, hasPrivilege } from '../../utils/access-control';
import { getNonHybridClusters } from '../../utils/cluster-utils';
import { getAccountUser } from '../_authenticated/_account';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Overview',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'overview',
  beforeLoad: async ({ context: { store, queryClient }, params: { accountId } }) => {
    const userAccount = await getAccountUser(store, {
      account_id: accountId,
    });

    if (hasPrivilege({ privileges: ['CLOUD_RBAC'], userAccount })) {
      const canWriteClusters = hasPermission({ permissions: ['write:clusters'], userAccount });
      const canReadClusters = hasPermission({ permissions: ['read:clusters'], userAccount });
      const canWriteApiKeys = hasPermission({ permissions: ['write:api-keys'], userAccount });
      const canReadAPIKeys = hasPermission({ permissions: ['read:api-keys'], userAccount });

      // The Overview stepper needs all of the following permissions to be able to show the stepper cards
      // the design needs to be thought out more.
      if (!canWriteClusters || !canWriteApiKeys || !canReadAPIKeys || !canReadClusters) {
        return {
          hasMissingPermissions: true,
          shouldShowWelcomeStepper: false,
          accountApiKeysQueryOptions: null,
          accountJwtsQueryOptions: null,
        };
      }
    }

    const accountApiKeysQueryOptions = getAccountApiKeys({ account_id: accountId });
    const accountJwtsQueryOptions = getAccountJwts({ account_id: accountId });

    const [clusters, apiKeys] = await Promise.all([
      store.dispatch(clusterApi.endpoints.getClustersByAccountId.initiate({ account_id: accountId })),
      queryClient.fetchQuery(accountApiKeysQueryOptions),
    ]);

    const jwts = accountJwtsQueryOptions ? await queryClient.fetchQuery(accountJwtsQueryOptions) : [];

    return {
      hasMissingPermissions: false,
      shouldShowWelcomeStepper: getNonHybridClusters(clusters?.data)?.length === 0 || !apiKeys.length || !jwts.length,
      accountApiKeysQueryOptions,
      accountJwtsQueryOptions,
    };
  },
}).lazy(() => import(/* webpackChunkName: "overview" */ './overview.lazy').then(({ Route }) => Route));
