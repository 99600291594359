import { z } from 'zod';
import {
  ClusterJwtDto,
  ClusterJwtPayloadAccess,
  TokenPayload,
  TokenPayloadAccess,
} from '../../../api/services/cluster';
import {
  formatDateTimeWithOffset,
  getDateFromUnixTimestamp,
  getMsTimestampFromUnixTimestamp,
} from '../../../utils/date-utils';
import { validators } from '../../../utils/form-utils';
import { keyValuePairValidator } from '../../../utils/kubernetes-utils';

export const MIN_CLUSTER_VERSION_FOR_RBAC = '1.11.0';

/**
 * Token has cluster-wide access when `access` is not an array of collections
 */
export const hasCollectionAccess = (
  payload: TokenPayload,
): payload is { access: ClusterJwtPayloadAccess[]; exp: number } => Array.isArray(payload.access);

export const getExpiresAt = (token: ClusterJwtDto): string =>
  token.jwt_payload.exp ? formatDateTimeWithOffset({ date: getDateFromUnixTimestamp(token.jwt_payload.exp) }) : '-';

function getCollectionAccessString(access: string) {
  if (access === 'r') {
    return 'READ';
  } else if (access === 'rw') {
    return 'READ/WRITE';
  }
  return '';
}

export function getClusterAccessString(access: TokenPayloadAccess): string {
  if (access === 'r') {
    return 'Read';
  } else if (access === 'm') {
    return 'Manage';
  }
  return '';
}

type TokenStatus = 'Active' | 'Expired' | 'Deleted';
export const getTokenStatusData = (
  token: ClusterJwtDto,
): { status: TokenStatus; color: 'error' | 'warning' | 'success' } => {
  const { jwt_payload: payload } = token;
  const { exp } = payload;
  const now = Date.now();
  if (exp && getMsTimestampFromUnixTimestamp(exp) < now) {
    return { status: 'Expired', color: 'error' };
  }
  return { status: 'Active', color: 'success' };
};

export function getCollectionAccessDataName(collectionData: NonNullable<ClusterJwtPayloadAccess>) {
  return `${getCollectionAccessString(collectionData.access)} ${collectionData.collection}`;
}

export const collectionsSchemaValidator = z.object({
  name: z.string().min(1),
  allowedOperations: z.enum(['rw', 'r']),
  payload: keyValuePairValidator.optional(),
});
export type CollectionsSchema = z.infer<typeof collectionsSchemaValidator>;

/**
 * Max Token Expiry was arbitrarily chosen to put a size limit on the input. And 10 years should suffice.
 */
export const MAX_TOKEN_EXPIRY_DAYS = 3650; // ~ 10 YEARS
export const tokenSchemaValidator = z
  .object({
    // Token name follows the same restrictions as the Cluster name.
    name: validators.clusterName,
    // When defining an optional number with a minimum, it has to be done through `refine` with a
    // string as an entry point (and then parsed inside), to prevent early validation failures.
    // At the same time this has to be combined with a `Controlled`
    expiration: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (value == null || value === '') {
            return true;
          }
          const parsedInt = parseInt(value);
          if (isNaN(parsedInt)) {
            return false;
          }

          // To prevent values such as '1.1', '1e3' or '1asads#' from being accepted, as parse int will return a number
          if (parsedInt.toString() !== value) {
            return false;
          }

          if (parsedInt < 1 || parsedInt > MAX_TOKEN_EXPIRY_DAYS) {
            return false;
          }
          return true;
        },
        { message: `Value must be an integer between 1 and ${MAX_TOKEN_EXPIRY_DAYS} when defined` },
      ),
    accessControl: z.enum(['clusterWide', 'perCollection'], {
      message: "Invalid accessControl, should be 'clusterWide' | 'perCollection'",
    }),
    allowedOperations: z.enum(['m', 'r']),
    collections: z.array(collectionsSchemaValidator).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.accessControl === 'perCollection') {
      if (!data.collections?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one collection is required',
          path: ['collections'],
        });
      }
    }
  });

export type TokenSchemaType = z.infer<typeof tokenSchemaValidator>;
