const MIN_TO_MS = 60000;

const DATE_FORMAT_CONFIG = Object.freeze({
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const TIME_FORMAT_CONFIG = Object.freeze({
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

const DATE_TIME_FORMAT_CONFIG = Object.freeze({
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

const LOG_DATE_TIME_FORMAT_CONFIG: Intl.DateTimeFormatOptions = Object.freeze({
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
});
const dateFormatter = new Intl.DateTimeFormat('en-US', DATE_FORMAT_CONFIG);
const timeFormatter = new Intl.DateTimeFormat('en-US', TIME_FORMAT_CONFIG);
const dateTimeFormatter = new Intl.DateTimeFormat('en-US', DATE_TIME_FORMAT_CONFIG);
const logDateTimeFormatter = new Intl.DateTimeFormat('en-US', LOG_DATE_TIME_FORMAT_CONFIG);

/**
 * Returns the UTC offset of the given date in the format of '±hh:mm'.
 */
export const getUTCOffset = (date: Date) => {
  const offset = date.getTimezoneOffset();
  if (offset === 0) {
    return 'UTC';
  }

  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset / 60);
  const minutes = absOffset % 60;
  const sign = offset <= 0 ? '+' : '-';
  return `UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

const formatWithOffset = ({
  date,
  utc = false,
  formatter,
}: {
  date: Date;
  utc?: boolean;
  formatter: Intl.DateTimeFormat;
}) => {
  if (utc) {
    const timezoneOffsetInMilliseconds = date.getTimezoneOffset() * MIN_TO_MS;
    const utcTime = date.getTime() + timezoneOffsetInMilliseconds;
    const utcDate = new Date(utcTime);
    const formattedDate = formatter.format(utcDate);
    return `${formattedDate} (UTC)`;
  }

  const formattedDate = formatter.format(date);
  const utcOffset = getUTCOffset(date);

  // Construct the final formatted string
  return `${formattedDate} (${utcOffset})`;
};

const formatLogDateTime = (date: Date) => {
  const parts = logDateTimeFormatter.formatToParts(date);
  const getPart = (type: keyof Intl.DateTimeFormatPartTypesRegistry) =>
    parts.find((p) => p.type === type)?.value.padStart(2, '0');

  const dateStr = `${getPart('year')}-${getPart('month')}-${getPart('day')}`;
  const timeStr = `${getPart('hour')}:${getPart('minute')}:${getPart('second')}`;
  return `${dateStr} ${timeStr}`;
};

export const formatLogDateTimeWithOffset = ({ date, utc = false }: { date: Date; utc?: boolean }) => {
  if (utc) {
    const timezoneOffsetInMilliseconds = date.getTimezoneOffset() * MIN_TO_MS;
    const utcTime = date.getTime() + timezoneOffsetInMilliseconds;
    const utcDate = new Date(utcTime);
    const formattedDate = formatLogDateTime(utcDate);
    return `${formattedDate} UTC`;
  }

  const formattedDate = formatLogDateTime(date);
  const utcOffset = getUTCOffset(date);

  // Construct the final formatted string
  return `${formattedDate} ${utcOffset}`;
};

/**
 * Formats the given date and time to the format 'MMM dd, yyyy h:mm a (UTC±hh:mm)'.
 * If utc flag is set to true, the date and time are formatted in UTC timezone 'MMM dd, yyyy h:mm a (UTC)'.
 */
export const formatDateTimeWithOffset = ({ date, utc = false }: { date: Date; utc?: boolean }) =>
  formatWithOffset({ date, utc, formatter: dateTimeFormatter });

/**
 * Formats the given date to the format 'h:mm a (UTC±hh:mm)'.
 * If utc flag is set to true, time is formatted in UTC timezone 'h:mm a (UTC)'.
 */
export const formatTimeWithOffset = ({ date, utc = false }: { date: Date; utc?: boolean }) =>
  formatWithOffset({ date, utc, formatter: timeFormatter });

export const formatDate = (date: Date) => dateFormatter.format(date);

export const getMsTimestampFromUnixTimestamp = (unixTimestamp: number) => unixTimestamp * 1000;
export const getMsTimestampFromNanoTimestamp = (nanoTimestamp: number) => nanoTimestamp / 1e6;
export const getDateFromUnixTimestamp = (unixTimestamp: number) =>
  new Date(getMsTimestampFromUnixTimestamp(unixTimestamp));

export const getUnixTimestampFromMs = (ms: number) => ms / 1000;

/**
 * Transforms a given amount of days into milliseconds
 */
export const convertDaysToMS = (days: number) => days * 24 * 60 * 60 * 1000;
