import { createRoute, LinkProps, notFound, redirect } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '..';
import { UserAccount } from '../../../services/iamApi';
import { hasPermission, hasPrivilege } from '../../../utils/access-control';
import { getAccountUser } from '../../_authenticated/_account';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Cloud UI Access',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'cloud-access',
  beforeLoad: async ({ context: { store }, params, ...rest }) => {
    const userAccount = await getAccountUser(store, { account_id: params.accountId });
    const redirectionRoute = resolveRedirectPath(userAccount);

    if (!redirectionRoute) {
      throw notFound();
    }

    const activeURL = rest.matches.at(-1)?.fullPath as string;

    if (activeURL && doesURLExist(activeURL)) {
      const isValid = isCurrentURLValid(userAccount, activeURL);
      if (!isValid) {
        throw notFound();
      }
    }

    const isExactMatch = rest.matches.at(-1)?.routeId === Route.id;

    if (isExactMatch) {
      throw redirect({ to: redirectionRoute, params });
    }
  },
}).lazy(() => import(/* webpackChunkName: "cloud-access" */ './index.lazy').then(({ Route }) => Route));

type RouteConfig = {
  path: LinkProps['to'];
  check: (userAccount: UserAccount) => boolean;
  priority: number;
};

export const ACCESS_ROUTES: RouteConfig[] = [
  {
    path: '/accounts/$accountId/cloud-access/roles/all-users',
    check: (userAccount) => {
      const hasUserAccess = hasPermission({
        permissions: ['read:account_users'],
        userAccount,
      });
      return hasUserAccess;
    },
    priority: 1,
  },
  {
    path: '/accounts/$accountId/cloud-access/cloud-management-keys',
    check: (userAccount) =>
      hasPrivilege({ privileges: ['PROGRAMMATIC_ACCESS'], userAccount }) &&
      hasPermission({ permissions: ['read:programmatic_access_keys'], userAccount }),
    priority: 2,
  },
  {
    path: '/accounts/$accountId/cloud-access/database-api-keys',
    check: (userAccount) => hasPermission({ permissions: ['read:api-keys'], userAccount }),
    priority: 3,
  },
];

const resolveRedirectPath = (userAccount: UserAccount): string => {
  const route = ACCESS_ROUTES.sort((a, b) => a.priority - b.priority).find((route) => route.check(userAccount));

  return route?.path ?? '';
};

export const isCurrentURLValid = (userAccount: UserAccount, path: string): boolean => {
  const route = ACCESS_ROUTES.find((route) => route.path === path);
  return route?.check(userAccount) ?? false;
};

export const doesURLExist = (path: string): boolean => Boolean(ACCESS_ROUTES.find((route) => route.path === path));
