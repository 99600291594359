import createClient, { Middleware } from 'openapi-fetch';
import { paths, QdrantErrorCode } from '../api-schema';
import { ComponentSchema } from '../utils/api-schema-utils';

export const client = createClient<paths>({ baseUrl: '/api/v1' });

/**
 * Middleware to add Authorization header to all requests.
 * The 'token' is set externally by the app.
 */
export const authMiddleware: Middleware & { token: string } = {
  token: '',
  onRequest({ request }) {
    if (this.token) {
      request.headers.set('Authorization', `Bearer ${this.token}`);
      return request;
    }
  },
};

client.use(authMiddleware);

const throwOnErrorMiddleware: Middleware = {
  async onResponse({ response }) {
    if (!response.ok) {
      let error: string | object = await response.clone().text();
      try {
        error = JSON.parse(error) as object;
      } catch {
        // noop
      }
      throw new ApiClientError(error, response.status);
    }
  },
};

client.use(throwOnErrorMiddleware);

// eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
export class ApiClientError<T = Object> extends Error {
  static displayName = 'ApiClientError';
  override readonly name = 'ApiClientError';

  constructor(
    public error: T,
    readonly originalStatus: number,
  ) {
    super();
  }

  override get message() {
    let message = `(HTTP ${this.originalStatus})`;
    if (isJsonApiResponseError(this.error)) {
      message += `: ${this.error.detail}`;
    }
    return message;
  }

  override toString() {
    const errorMessage = typeof this.error === 'string' ? this.error.toString() : JSON.stringify(this.error);

    return `(HTTP ${this.originalStatus}) <${errorMessage}>`;
  }
}

export function isApiClientError(error: unknown): error is ApiClientError {
  return error instanceof ApiClientError;
}

export function isJsonApiClientError(error: unknown): error is ApiClientError<{ detail: unknown }> {
  return isApiClientError(error) && isJsonApiResponseError(error.error);
}

export function isJsonApiResponseError(error: unknown): error is { detail: string } {
  return typeof error === 'object' && error != null && 'detail' in error && typeof error.detail === 'string';
}

export function isApiValidationError<TDetail extends readonly ComponentSchema<'ValidationError'>[]>(
  error: unknown,
): error is ApiClientError<{ detail: TDetail }> {
  if (isJsonApiClientError(error)) {
    const { error: e } = error;
    if (Array.isArray(e.detail)) {
      const val = e.detail as TDetail[number][];
      return val.every((v) => 'type' in v && 'msg' in v && 'loc' in v);
    }
  }
  return false;
}

export function isApiCodeError(
  error: unknown,
): error is ApiClientError<{ detail: ComponentSchema<'QdrantErrorCode'> }> {
  if (isJsonApiClientError(error)) {
    const { error: e } = error;
    return typeof e.detail === 'string' && /^E(\d{4})$/.test(e.detail);
  }
  return false;
}

export function isErrorWithDataDetail(error: unknown): error is {
  data: { detail: ComponentSchema<'QdrantErrorCode'> };
} {
  return Boolean(
    typeof error === 'object' &&
      error &&
      'data' in error &&
      typeof error.data === 'object' &&
      error.data &&
      'detail' in error.data,
  );
}

export { QdrantErrorCode };
