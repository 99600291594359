import { createRoute, Outlet, useChildMatches } from '@tanstack/react-router';
import { AccountDashboard, AccountDashboardOutline } from '../../components/AccountDashboard';
import { AccountDashboardErrorComponent } from '../../components/AccountDashboard/AccountDashboardErrorComponent';
import { createRouteErrorComponent } from '../../router/createRouteErrorComponent';
import { Route as AccountIdRoute } from '../_authenticated/accounts_/$accountId';

export const Route = createRoute({
  getParentRoute: () => AccountIdRoute,
  id: 'account-dashboard',
  component: function AccountDashboardComponent() {
    if (useChildMatchesClusterSetup()) {
      return <Outlet />;
    }

    return (
      <AccountDashboard>
        <Outlet />
      </AccountDashboard>
    );
  },
  errorComponent: createRouteErrorComponent(function AccountDashboardErrorComponentImpl(props) {
    if (useChildMatchesClusterSetup()) {
      return (
        <AccountDashboardOutline>
          <AccountDashboardErrorComponent {...props} />
        </AccountDashboardOutline>
      );
    }

    return (
      <AccountDashboard>
        <AccountDashboardErrorComponent {...props} />
      </AccountDashboard>
    );
  }),
});

function useChildMatchesClusterSetup() {
  return useChildMatches({
    select: (matches) =>
      matches.find(
        (match) =>
          match.fullPath === '/accounts/$accountId/clusters/$clusterId/scale' ||
          match.fullPath === '/accounts/$accountId/clusters/create',
      ),
  });
}
