import { createRouteMask } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '../routes/(account-dashboard)';
import { Route as BackupsRoute } from '../routes/(account-dashboard)/backups';
import { Route as BillingRoute } from '../routes/(account-dashboard)/billing';
import { Route as ProgrammaticAccessRoute } from '../routes/(account-dashboard)/cloud-access/programmatic-access';
import { Route as RolesRoute } from '../routes/(account-dashboard)/cloud-access/roles';
import { Route as RoleIdRoute } from '../routes/(account-dashboard)/cloud-access/roles.$roleId';
import { Route as RoleIdPermissions } from '../routes/(account-dashboard)/cloud-access/roles.$roleId.permissions';
import { Route as RoleIdUsers } from '../routes/(account-dashboard)/cloud-access/roles.$roleId.users';
import { Route as AllUsersRoute } from '../routes/(account-dashboard)/cloud-access/roles.all-users';
import { Route as CloudAccessRoute } from '../routes/(account-dashboard)/cloud-access_';
import { Route as ClustersIndexRoute } from '../routes/(account-dashboard)/clusters';
import { Route as ClusterIdRoute } from '../routes/(account-dashboard)/clusters/$clusterId';
import { Route as ClusterApiKeysRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/api-keys';
import { Route as ClusterBackupsRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/backups';
import { Route as ClusterDeletionRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/cluster-deletion';
import { Route as ClusterConfigsRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/configs';
import { Route as ClusterKubeConfigsRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/kube-config';
import { Route as ClusterLogsRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/logs';
import { Route as ClusterMetricsRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/metrics';
import { Route as ClusterOverviewRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/overview';
import { Route as ClusterRequestRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/requests';
import { Route as ClusterUsageRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index/usage';
import { Route as ClusterIdIndexRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index_';
import { Route as ClusterIdScaleRoute } from '../routes/(account-dashboard)/clusters/$clusterId.scale';
import { Route as ClusterCreateRoute } from '../routes/(account-dashboard)/clusters/create';
import { Route as ClustersRoute } from '../routes/(account-dashboard)/clusters_';
import { Route as DataAccessRoute } from '../routes/(account-dashboard)/data-access';
import { Route as HybridCloudRoute } from '../routes/(account-dashboard)/hybrid-cloud_';
import { Route as HybridCloudEnvironmentRoute } from '../routes/(account-dashboard)/hybrid_cloud/$hybridCloudEnvId';
import { Route as HybridCloudEnvironmentEditRoute } from '../routes/(account-dashboard)/hybrid_cloud/$hybridCloudEnvId.edit';
import { Route as HybridCloudCreateRoute } from '../routes/(account-dashboard)/hybrid_cloud/create';
import { Route as HybridCloudOnboardingConfigurationRoute } from '../routes/(account-dashboard)/hybrid_cloud/onboarding/configuration';
import { Route as HybridCloudOnboardingConnectionRoute } from '../routes/(account-dashboard)/hybrid_cloud/onboarding/connection';
import { Route as HybridCloudOnboardingPaymentRoute } from '../routes/(account-dashboard)/hybrid_cloud/onboarding/payment';
import { Route as HybridCloudOnboardingRoute } from '../routes/(account-dashboard)/hybrid_cloud/onboarding_';
import { Route as AccountOverviewRoute } from '../routes/(account-dashboard)/overview';
import { Route as AccountProfileRoute } from '../routes/(account-dashboard)/profile';
import { Route as RootRoute } from '../routes/__root';
import { Route as AuthenticatedRoute } from '../routes/_authenticated';
import { Route as AccountRoute } from '../routes/_authenticated/_account';
import { Route as OverviewRoute } from '../routes/_authenticated/_account/overview';
import { Route as ProfileRoute } from '../routes/_authenticated/_account/profile';
import { Route as AccountIdRoute } from '../routes/_authenticated/accounts_/$accountId';
import { Route as CalculatorRoute } from '../routes/calculator_';
import { Route as IndexRoute } from '../routes/index';
import { Route as LegalRoute } from '../routes/legal_';
import { Route as LoginRoute } from '../routes/login_';
import { Route as LogoutRoute } from '../routes/logout_';
import { Route as SignupRoute } from '../routes/signup_';

export const routeTree = RootRoute.addChildren([
  IndexRoute,
  LoginRoute,
  SignupRoute,
  LogoutRoute,
  CalculatorRoute,
  LegalRoute,
  AuthenticatedRoute.addChildren([
    AccountRoute.addChildren([OverviewRoute, ProfileRoute]),
    AccountIdRoute.addChildren([
      AccountDashboardRoute.addChildren([
        AccountOverviewRoute,
        AccountProfileRoute,
        ClustersRoute.addChildren([
          ClustersIndexRoute,
          ClusterCreateRoute,
          ClusterIdRoute.addChildren([
            ClusterIdIndexRoute.addChildren([
              ClusterApiKeysRoute,
              ClusterOverviewRoute,
              ClusterUsageRoute,
              ClusterMetricsRoute,
              ClusterRequestRoute,
              ClusterBackupsRoute,
              ClusterConfigsRoute,
              ClusterKubeConfigsRoute,
              ClusterLogsRoute,
              ClusterDeletionRoute,
            ]),
            ClusterIdScaleRoute,
          ]),
        ]),
        HybridCloudRoute.addChildren([
          HybridCloudCreateRoute,
          HybridCloudOnboardingRoute.addChildren([
            HybridCloudOnboardingPaymentRoute,
            HybridCloudOnboardingConfigurationRoute,
            HybridCloudOnboardingConnectionRoute,
          ]),
          HybridCloudEnvironmentRoute.addChildren([HybridCloudEnvironmentEditRoute]),
        ]),
        BackupsRoute,
        CloudAccessRoute.addChildren([
          RolesRoute.addChildren([AllUsersRoute, RoleIdRoute.addChildren([RoleIdUsers, RoleIdPermissions])]),
          ProgrammaticAccessRoute,
        ]),
        DataAccessRoute,
        BillingRoute,
      ]),
    ]),
  ]),
]);

export const ProfileRouteMask = createRouteMask({
  routeTree,
  from: '/accounts/$accountId/profile',
  to: '/profile',
  params: true,
});
