import { createRoute } from '@tanstack/react-router';
import { Route as ClusterIdIndexRoute } from '../$clusterId.index_';
import { withRouteAccessControl } from '../../../../router/withRouteAccessControl';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => ClusterIdIndexRoute,
    path: 'cluster-deletion',
    staticData: {
      permissions: ['delete:clusters'],
    },
  }),
).lazy(() => import(/* webpackChunkName: "cluster-deletion" */ './cluster-deletion.lazy').then(({ Route }) => Route));
