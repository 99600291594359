import { createRoute, redirect } from '@tanstack/react-router';
import { Route as ClusterIdIndexRoute } from '../$clusterId.index_';
import { getAccountApiKeys } from '../../../../api/services/authentication';
import { getClusterByIdQuery, getClusterJwts } from '../../../../api/services/cluster';
import { featureFlagsQueryOptions } from '../../../../api/services/core';
import { MIN_CLUSTER_VERSION_FOR_RBAC } from '../../../../components/Access/RBAC/utils';
import { withRouteAccessControl } from '../../../../router/withRouteAccessControl';
import { hasClusterRbacEnabled, isClusterHybridCloud } from '../../../../utils/cluster-utils';
import { isAtLeastVersion } from '../../../../utils/helpers';
import { getAccountUser } from '../../../_authenticated/_account';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => ClusterIdIndexRoute,
    path: 'api-keys',
    staticData: {
      permissions: ['read:api-keys'],
    },
    beforeLoad: async ({ context: { store, queryClient }, params: { accountId, clusterId } }) => {
      const accountApiKeysQueryOptions = getAccountApiKeys({ account_id: accountId }, { cluster_id: clusterId });

      const [cluster, { qdrant_clusters_jwt_authentication_enabled: jwtsEnabled }] = await Promise.all([
        queryClient.fetchQuery(getClusterByIdQuery({ account_id: accountId, cluster_id: clusterId })),
        queryClient.ensureQueryData(featureFlagsQueryOptions),
        getAccountUser(store, { account_id: accountId }),
      ]);

      const isHybridCloudCluster = isClusterHybridCloud(cluster);

      if (isHybridCloudCluster) {
        throw redirect({
          to: '/accounts/$accountId/clusters/$clusterId/overview',
          params: { accountId, clusterId },
          replace: true,
        });
      }

      const isClusterRbacEnabled = hasClusterRbacEnabled(cluster);
      const clusterIsCompatibleWithRbac = isAtLeastVersion(MIN_CLUSTER_VERSION_FOR_RBAC, cluster.version);

      if (!(jwtsEnabled && isClusterRbacEnabled && clusterIsCompatibleWithRbac)) {
        return { jwtsEnabled, accountApiKeysQueryOptions, v: null };
      }

      return {
        jwtsEnabled,
        accountApiKeysQueryOptions,
        clusterJwtQueryOptions: getClusterJwts({ account_id: accountId, cluster_id: clusterId }),
      };
    },
    loader: async ({ context: { queryClient, clusterJwtQueryOptions, accountApiKeysQueryOptions } }) => {
      await Promise.all(
        [
          queryClient.fetchQuery(accountApiKeysQueryOptions),
          clusterJwtQueryOptions ? queryClient.fetchQuery(clusterJwtQueryOptions) : undefined,
        ].filter(Boolean),
      );
    },
  }),
).lazy(() => import(/* webpackChunkName: "cluster-api-keys" */ './api-keys.lazy').then(({ Route }) => Route));
