import { createRoute, redirect } from '@tanstack/react-router';
import { Route as ClusterIdRoute } from '../$clusterId';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Cluster',
    },
  ],
  getParentRoute: () => ClusterIdRoute,
  path: '/',
  beforeLoad: ({ params, matches }) => {
    const isExactMatch = matches.at(-1)?.routeId === Route.id;

    if (isExactMatch) {
      throw redirect({
        to: '/accounts/$accountId/clusters/$clusterId/overview',
        params,
        replace: true,
      });
    }
  },
}).lazy(() => import(/* webpackChunkName: "cluster-id-index" */ './index.lazy').then(({ Route }) => Route));
