import { createRoute } from '@tanstack/react-router';
import { Route as ClusterIdIndexRoute } from '../$clusterId.index_';
import { getClusterEventsQuery } from '../../../../api/services/cluster';
import { getHybridCloudEnvQuery } from '../../../../api/services/cluster/hybrid-cloud';

export const Route = createRoute({
  getParentRoute: () => ClusterIdIndexRoute,
  path: 'logs',
  beforeLoad: async ({ context: { queryClient, clusterQueryOptions }, params }) => {
    const cluster = await queryClient.fetchQuery(clusterQueryOptions);

    const { accountId, clusterId } = params;
    if (!cluster?.private_region_id) {
      return {
        hybridCloudQueryOptions: null,
        clusterEventsOptions: null,
      };
    }

    return {
      hybridCloudQueryOptions: getHybridCloudEnvQuery({
        account_id: accountId,
        hybrid_cloud_env_id: String(cluster.private_region_id),
      }),
      clusterEventsOptions: getClusterEventsQuery({
        account_id: accountId,
        cluster_id: clusterId,
      }),
    };
  },

  loader({ context: { queryClient, clusterEventsOptions, hybridCloudQueryOptions } }) {
    // When it's a hybrid cloud environment we need both the hybrid cloud env data and the events logs
    if (hybridCloudQueryOptions) {
      void queryClient.prefetchQuery(hybridCloudQueryOptions);
      void queryClient.prefetchQuery(clusterEventsOptions);
    }
  },
}).lazy(() => import(/* webpackChunkName: "cluster-logs" */ './logs.lazy').then(({ Route }) => Route));
