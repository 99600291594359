import { Skeleton } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as CloudAccessRoute } from './cloud-access_';
import { getAccountJwts } from '../../api/services/account';
import { getAccountApiKeys } from '../../api/services/authentication';
import { featureFlagsQueryOptions } from '../../api/services/core';
import { withRouteAccessControl } from '../../router/withRouteAccessControl';
import { clusterApi } from '../../services/clustersApi';
import { getAccountUser } from '../_authenticated/_account';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: 'Data Access Control',
      },
    ],
    staticData: {
      permissions: ['read:api-keys'],
    },
    getParentRoute: () => CloudAccessRoute,
    path: 'database-api-keys',
    beforeLoad: async ({ context: { store, queryClient }, params: { accountId } }) => {
      const accountApiKeysQueryOptions = getAccountApiKeys({ account_id: accountId });

      const [{ qdrant_clusters_jwt_authentication_enabled: jwtsEnabled }] = await Promise.all([
        queryClient.ensureQueryData(featureFlagsQueryOptions),
        getAccountUser(store, { account_id: accountId }),
      ]);

      if (!jwtsEnabled) {
        return { accountApiKeysQueryOptions, accountJwtQueryOptions: null };
      }

      return {
        accountApiKeysQueryOptions,
        accountJwtQueryOptions: getAccountJwts({ account_id: accountId }),
      };
    },
    loader: async ({
      context: { queryClient, store, accountJwtQueryOptions, accountApiKeysQueryOptions },
      params: { accountId },
    }) => {
      await Promise.all(
        [
          store.dispatch(clusterApi.endpoints.getClustersByAccountId.initiate({ account_id: accountId })).unwrap(),
          queryClient.fetchQuery(accountApiKeysQueryOptions),
          accountJwtQueryOptions ? queryClient.fetchQuery(accountJwtQueryOptions) : undefined,
        ].filter(Boolean),
      );
    },
    pendingComponent: () => <Skeleton variant="rectangular" height={240} />,
  }),
).lazy(() => import(/* webpackChunkName: "data-access" */ './data-access.lazy').then(({ Route }) => Route));
