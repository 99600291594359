import { createRoute, redirect } from '@tanstack/react-router';
import { Route as ClusterIdIndexRoute } from '../$clusterId.index_';
import { isClusterHybridCloud } from '../../../../utils/cluster-utils';

export const Route = createRoute({
  getParentRoute: () => ClusterIdIndexRoute,
  path: 'kube-config',
  beforeLoad: async ({ context: { queryClient, clusterQueryOptions }, params: { accountId, clusterId } }) => {
    const cluster = await queryClient.fetchQuery(clusterQueryOptions);

    const isHybridCloudCluster = isClusterHybridCloud(cluster);

    if (!isHybridCloudCluster) {
      throw redirect({
        to: '/accounts/$accountId/clusters/$clusterId/overview',
        params: { accountId, clusterId },
        replace: true,
      });
    }
  },
}).lazy(() => import(/* webpackChunkName: "cluster-kube-config" */ './kube-config.lazy').then(({ Route }) => Route));
